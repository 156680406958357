import '@css/main.scss';
import '@js/bootstrap';

import {Header} from '@js/components/Header';
import {Slider} from '@js/components/Slider';
import {Filters} from "@js/utils/Filters";
import {Archive} from "@js/utils/Archive";
import {Burger} from "@js/components/Burger";
import {Accordeon} from "@js/components/Accordeon";

window.onload = () => {

  new Header()
  new Slider()
  new Filters()
  new Archive()
  new Burger()
  new Accordeon()

}
