export class Burger {
  constructor() {
    this._mobileHeader = document.querySelector('.header-nav__burger');

    if (!this._mobileHeader) {
      return;
    }

    this.init()
  }

  init() {
    this._menu = this._mobileHeader.querySelector('.menu');
    this._menu.querySelectorAll('.menu-item-has-children').forEach(item => {
      item.addEventListener('click', function (e) {
        this._submenu = e.target.parentNode.querySelector('.sub-menu');

        if (e.target.parentNode.classList.contains('menu-item-has-children')) {
          e.preventDefault();

          let countEl = e.target.parentNode.querySelectorAll(".back").length
          if (e.target.parentNode.classList.contains('menu-item-type-taxonomy') && countEl === 0) {
            this._elLink = document.createElement('a');
            this._elLink.classList.add('link');
            this._elLink.href = e.target.href;
            this._elLink.innerHTML = e.target.innerHTML + '<svg width="43" height="30" viewBox="0 0 43 30" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
              '<path d="M37.9746 13.5417L27.0809 2.64795C26.797 2.36407 26.6482 2.02962 26.6346 1.64462C26.623 1.25962 26.7717 0.906709 27.0809 0.585876C27.4036 0.263097 27.7507 0.0987905 28.1221 0.0929559C28.4935 0.0890668 28.8406 0.247541 29.1634 0.568374L41.9442 13.3521C42.4167 13.8227 42.653 14.372 42.653 15C42.653 15.6281 42.4167 16.1774 41.9442 16.648L29.1634 29.4317C28.8795 29.7156 28.5421 29.8643 28.1513 29.878C27.7605 29.8916 27.4036 29.737 27.0809 29.4142C26.7698 29.0934 26.6123 28.7492 26.6084 28.3817C26.6025 28.0162 26.76 27.673 27.0809 27.3521L37.9746 16.4584L1.86629 16.4584C1.45018 16.4584 1.10213 16.3194 0.822123 16.0413C0.54407 15.7613 0.405043 15.4142 0.405043 15C0.405043 14.5859 0.54407 14.2388 0.822123 13.9588C1.10213 13.6807 1.44921 13.5417 1.86338 13.5417L37.9746 13.5417Z" fill="currentColor"/>\n';
            this._submenu.appendChild(this._elLink);
          }
        }

        this._submenu.classList.add('active');
        this._el = document.createElement('div');
        this._el.classList.add('back');
        this._el.innerHTML = '<svg width="43" height="30" viewBox="0 0 43 30" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<path d="M37.9746 13.5417L27.0809 2.64795C26.797 2.36407 26.6482 2.02962 26.6346 1.64462C26.623 1.25962 26.7717 0.906709 27.0809 0.585876C27.4036 0.263097 27.7507 0.0987905 28.1221 0.0929559C28.4935 0.0890668 28.8406 0.247541 29.1634 0.568374L41.9442 13.3521C42.4167 13.8227 42.653 14.372 42.653 15C42.653 15.6281 42.4167 16.1774 41.9442 16.648L29.1634 29.4317C28.8795 29.7156 28.5421 29.8643 28.1513 29.878C27.7605 29.8916 27.4036 29.737 27.0809 29.4142C26.7698 29.0934 26.6123 28.7492 26.6084 28.3817C26.6025 28.0162 26.76 27.673 27.0809 27.3521L37.9746 16.4584L1.86629 16.4584C1.45018 16.4584 1.10213 16.3194 0.822123 16.0413C0.54407 15.7613 0.405043 15.4142 0.405043 15C0.405043 14.5859 0.54407 14.2388 0.822123 13.9588C1.10213 13.6807 1.44921 13.5417 1.86338 13.5417L37.9746 13.5417Z" fill="currentColor"/>\n' +
          '</svg>\n' +
          '<span>Retour</span>';

        this._submenu.appendChild(this._el);
        this._el.addEventListener('click', () => {
          this._submenu.classList.remove('active')
          this._el.remove()
          this._elLink.remove()
        })

        return true;
      })
    })
  }
}
