import Swiper, {Pagination, Autoplay, Navigation, Scrollbar} from "swiper";


export class Slider {

  constructor() {

    document.querySelectorAll('*[slider]').forEach(slider => {

      this.registerSlider(slider)

    })
  }

  registerSlider(slider) {

    let name = slider.getAttribute('data-slider')
    let slider_id = slider.getAttribute('slider-id')
    let per_view = slider.getAttribute('per-view')
    let per_view_sm = slider.getAttribute('per-view-sm')
    let per_view_md = slider.getAttribute('per-view-md')
    let per_view_xs = slider.getAttribute('per-view-xs')
    let autoheight = parseInt(slider.getAttribute('autoheight'))
    let nav = slider.getAttribute('navigation')
    let sco = slider.getAttribute('scrollbar')
    let space = slider.getAttribute('data-spacing') ?? 20;
    let autoplay = slider.getAttribute('data-autoplay') ?? 0;


    const modules = [Pagination, Autoplay, Scrollbar]
    let navigation = {}
    let pagination = {}
    let scrollbar = {}

    if (sco === '1'){
      modules.shift()
      modules.push(Scrollbar)
      scrollbar = {
        el:".swiper-scrollbar-"+ slider_id,
        draggable: true,
      }
    }

    if (nav === 'arrow') {
      modules.shift()
      modules.push(Navigation)

      navigation = {
        nextEl: '.swiper-button-next-'+ slider_id,
        prevEl: '.swiper-button-prev-'+ slider_id,
      }
    }

    if (autoplay){
      modules.shift()
      modules.push(Autoplay)

      autoplay = {
        delay: 2000,
      }
    }

    const
      swiper = new Swiper('.' + name, {
        modules: modules,
        slidesPerView: per_view,
        loop: false,
        spaceBetween: space,
        navigation: navigation,
        pagination: pagination,
        scrollbar: scrollbar,
        snapOnRelease : true,
        autoHeight: autoheight === 1,
        autoplay: autoplay,
        breakpoints: {
          300: {
            slidesPerView: per_view_xs,
          },
          480: {
            slidesPerView: per_view_sm
          },
          768: {
            slidesPerView: per_view_md
          },
          1024: {
            slidesPerView: per_view
          }
        }
      });
  }
}
