export class Archive {
  constructor() {

    this.container = document.querySelector('*[data-archive]')
    if (!this.container) {
      return;
    }

    this.type = this.container.getAttribute('data-archive')

    this.filters_container = this.container.querySelector('*[data-filters]');
    this.filters_type = this.filters_container.getAttribute('data-filters');
    this.filters = this.filters_container.querySelectorAll('*[data-filter]');
    this.itemsContainer = this.container.querySelector('.s-all-posts-grid')
    this.init()
  }

  init() {
    this.config = this.defaults()
    this.url = `${window.location.protocol}//${window.location.hostname}:8080/wp-json/akyos/v1/${this.type}`
    this.register()
  }

  defaults() {
    return {
      noMoreItems: false,
      currentTax: false,
    }
  }

  reset() {
    this.filters.forEach(f => f.classList.remove('active'))
    this.config = this.defaults()
    this.itemsContainer.innerHTML = "";
  }

  register() {
    this.filters.forEach(f => {
      f.addEventListener('click', (e) => {
        const id = f.dataset.filter
        e.preventDefault();
        this.reset()

        f.classList.add('active')

        this.config.currentTax = id
        this.fetch(true)
      })
    })
  }

  fetch(reset = false) {
    const params = new URLSearchParams();
    if (this.config.currentTax && this.config.currentTax !== "*") {
      params.append(this.filters_type, this.config.currentTax)
    }

    fetch(`${this.url}?${params.toString()}`)
      .then((r) => r.json())
      .then(r => {

        console.log(r)
        console.log()

        if (reset) {
          this.itemsContainer.innerHTML = "";
        }

        if (r.length === 0) {
          this.itemsContainer.innerHTML = 'Aucun résultat trouvé';
          this.config.noMoreItems = true;
        } else {
          this.appendItems(r);
        }


      })
      .catch(r => {
        console.log(r)
        this.config.noMoreItems = true;
      })
  }


  appendItems(items) {

    const template = document.querySelector('#archive-el');

    items.forEach((item) => {
      let clone = template.content.cloneNode(true);
      clone = this.itemPost(clone, item)
      this.itemsContainer.appendChild(clone)

    })

  }


  itemPost(clone, item) {

    const thumb = clone.querySelector('.c-post2-img');
    const title = clone.querySelector('.c-post2 h3');
    const date = clone.querySelector('.c-post2__content-date');
    const links = clone.querySelectorAll('.c-post2 a');

    const thumbnail = document.createElement('img')
    thumbnail.setAttribute('src', item.thumbnail)

    thumb.appendChild(thumbnail)

    if (item.post_title) {
      title.innerHTML = item.post_title

    }

    if (item.date) {
      date.innerHTML = item.date
    }



    if (links) {
      links.forEach((link) => {
        link.setAttribute('href', item.link)
      });
    }

    return clone

  }


}

