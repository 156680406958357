export class Accordeon {
  constructor() {
    this._accordeons = document.querySelectorAll('details')

    if (!this._accordeons.length) {
      return
    }

    this.init();
  }

  init() {
    this._accordeons.forEach(accordeon => {
      accordeon.addEventListener('click', () => {
        this._accordeons.forEach(accordeon => {
          if (accordeon !== this) {
            accordeon.removeAttribute('open')
          }
        })
      })
    })
  }
}
